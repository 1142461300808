exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-quiz-index-tsx": () => import("./../../../src/pages/quiz/index.tsx" /* webpackChunkName: "component---src-pages-quiz-index-tsx" */),
  "component---src-pages-quiz-sanity-quiz-slug-current-tsx": () => import("./../../../src/pages/quiz/{SanityQuiz.slug__current}.tsx" /* webpackChunkName: "component---src-pages-quiz-sanity-quiz-slug-current-tsx" */),
  "component---src-pages-sanity-category-slug-current-tsx": () => import("./../../../src/pages/{SanityCategory.slug__current}.tsx" /* webpackChunkName: "component---src-pages-sanity-category-slug-current-tsx" */),
  "component---src-pages-sanity-page-slug-current-tsx": () => import("./../../../src/pages/{SanityPage.slug__current}.tsx" /* webpackChunkName: "component---src-pages-sanity-page-slug-current-tsx" */),
  "component---src-pages-video-index-tsx": () => import("./../../../src/pages/video/index.tsx" /* webpackChunkName: "component---src-pages-video-index-tsx" */),
  "component---src-pages-video-sanity-video-slug-current-tsx": () => import("./../../../src/pages/video/{SanityVideo.slug__current}.tsx" /* webpackChunkName: "component---src-pages-video-sanity-video-slug-current-tsx" */)
}

